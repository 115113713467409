import React from "react";
import Img1 from "./spc bank logo.png";
import Phone from "./images/phone.png";
import Mail from "./images/mail.png";
import Website from "./images/website.png";

import "./SpcBank.css";

const SpcBankSpotlight = () => {
  return (
    <section id="" className="main_spc_bank">
      <div className="row mb-4 d-flex align-items-center">
        <div
          className="col-lg-2 col-md-6 col-sm-8 col-xs-6"
          style={{ background: "#017129" }}
        >
          <div className="p-2" style={{ width: "100%" }}>
            <div className="">
              <h4 className="text-white">Future is Here</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-10 col-md-6 col-sm-4 col-xs-6">
          <hr />
        </div>
      </div>
      <div className="container mt-3 mb-3">
        <div className="row no-gutters">
          <div className="image col-xl-5 m-auto">
            <a href="https://spcbank.ca" target="_blank">
              <img
                src={Img1}
                alt=""
                width={"90%"}
                style={{ borderRadius: "25px" }}
              />
            </a>
          </div>
          <div className="col-xl-7 ps-0 ps-lg-5 pe-lg-1 d-flex align-items-stretch">
            {/*Detailed cards of SPC bank */}
            {/*
            <div className="d-flex flex-column justify-content-center">
                <h2>SPC International Bank</h2>
                <h5>Your Gateway to Global Financial Excellence!</h5>
                <p>
                  Welcome to SPC International Bank, where innovation meets
                  reliability to redefine your financial journey. With
                  cutting-edge technology and a commitment to serving a wide
                  spectrum of clients, we make financial excellence attainable for
                  all. Discover a world of innovation, ease, and growth at SPC
                  Bank. <br />
                  <strong> Unlocking Financial Excellence:</strong> At SPC Bank,
                  we redefine private wealth management with a focus on
                  innovation, powered by Tata Consultancy Services (TCS)
                  technology and our strategic partners network worldwide. Our
                  mission is to provide innovative, reliable, and easy-to-use
                  financial solutions that drive growth for our clients.
                </p>
              </div>
            </div>
              <div className="row mt-4 d-flex justify-content-evenly">
              <div className="spc-bank-card p-3 col-md-3 icon-box">
                <div>
                  {" "}
                  <i className="bx bx-globe"></i>
                  <h4 className="main_spc_bank_green">
                    Global Reach, Local Care
                  </h4>
                  <p>
                    <ul>
                      <li>
                        <span className="main_spc_bank_red">
                          Innovative Perspective:
                        </span>{" "}
                        We combine global reach with a local touch, ensuring
                        innovative services that resonate with clients worldwide.
                      </li>
                      <li>
                        <span className="main_spc_bank_red">
                          Serving Beyond HNIs:
                        </span>{" "}
                        Our commitment extends to a broader range of clients,
                        delivering innovation to all, from high-net individuals to
                        corporations.
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="spc-bank-card p-3 col-md-3 icon-box">
                <div>
                  <i className="bx bx-cloud"></i>
                  <h4 className="main_spc_bank_green">
                    Cutting-Edge TCS Technology
                  </h4>
                  <p>
                    <ul>
                      <li>
                        <span className="main_spc_bank_red">
                          Innovation at Your Fingertips:
                        </span>{" "}
                        Our cutting-edge digital platform, driven by TCS
                        technology, brings innovation to your financial
                        management.
                      </li>
                      <li>
                        <span className="main_spc_bank_red">
                          Reliability in Technology:
                        </span>{" "}
                        Count on our secure and reliable technology for seamless
                        access, enabling innovation in your financial journey.
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="spc-bank-card p-3 col-md-3 icon-box">
                <div>
                  <i className="bx bx-money"></i>
                  <h4 className="main_spc_bank_green">
                    Comprehensive Financial Services
                  </h4>
                  <p>
                    <ul>
                      <li>
                        <span className="main_spc_bank_red">
                          Tailored Innovation:
                        </span>{" "}
                        Our range of services encompasses investment, estate
                        planning, tax optimisation, and more, innovatively
                        tailored to meet your unique financial needs.
                      </li>
                      <li>
                        <span className="main_spc_bank_red"> Ease of Use:</span>{" "}
                        Our innovative solutions make complex financial matters
                        easy to navigate for all clients.
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-5 mb-5 d-flex justify-content-evenly">
              <div className="spc-bank-card p-3 col-md-3 icon-box">
                <i className="bx bx-body"></i>
                <h4 className="main_spc_bank_green">
                  Asset Protection and Risk Management
                </h4>
                <p>
                  <ul>
                    <li>
                      <span className="main_spc_bank_red">
                        {" "}
                        Innovation for Security:
                      </span>{" "}
                      We employ innovative strategies to protect your assets and
                      manage risks, ensuring the reliability of your investments.
                    </li>
                    <li>
                      <span className="main_spc_bank_red"> Growth Focus:</span> By
                      securing your wealth, we enable you to focus on growth
                      opportunities.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="spc-bank-card p-3 col-md-3 icon-box">
                <i className="bx bx-receipt"></i>
                <h4 className="main_spc_bank_green">Tax and Estate Planning</h4>
                <p>
                  <ul>
                    <li>
                      <span className="main_spc_bank_red">
                        {" "}
                        Innovative Tax Strategies:
                      </span>{" "}
                      Our experts provide innovative tax optimization strategies
                      that lead to growth, not just in savings but in your
                      financial journey.
                    </li>
                    <li>
                      <span className="main_spc_bank_red">
                        {" "}
                        Simplicity and Reliability:
                      </span>{" "}
                      We make estate planning an easy and reliable process,
                      ensuring that your wealth is in the right hands.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="spc-bank-card p-3 col-md-3 icon-box">
                <i className="bx bx-donate-heart"></i>
                <h4 className="main_spc_bank_green">
                  Philanthropy and Social Responsibility
                </h4>
                <p>
                  <ul>
                    <li>
                      <span className="main_spc_bank_red">
                        {" "}
                        Innovation in Giving:{" "}
                      </span>
                      We assist with innovative philanthropic endeavours,
                      supporting clients' growth in their social responsibility
                      initiatives.
                    </li>
                    <li>
                      <span className="main_spc_bank_red">
                        {" "}
                        A Broader Horizon:
                      </span>{" "}
                      Beyond HNIs, we are here to support all clients, cultivating
                      growth in their philanthropic and financial aspirations.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
       */}
            <div>
              <p>
                {" "}
                <strong>
                  <i>
                    “Welcome to SPC Bank- a private international bank, your
                    gateway to financial innovation, reliability, ease of use,
                    and growth. We're dedicated to serving a wide spectrum of
                    clients, making financial excellence attainable for all.”
                  </i>{" "}
                </strong>{" "}
              </p>
              Please connect with us for more information;
              <br />
              <div className="d-flex">
                <box-icon name="location-plus"></box-icon>
                <span>
                  SPC Bank - a private international bank. <br />
                  Charlestown, <br />
                  Nevis, WI
                </span>
              </div>
              <div>
                <a href="mailto:info@spcbank.ch">
                  {" "}
                  <img
                    style={{ border: "1px solid red", borderRadius: "50%" }}
                    className="m-2"
                    src={Mail}
                    alt=""
                    width={"40px"}
                  />
                </a>
                <a href="tel:+18696682050">
                  {" "}
                  <img className="m-2" src={Phone} alt="" width={"40px"} />
                </a>{" "}
                <a href="https://spcbank.ca" target="_blank">
                  {" "}
                  <img className="m-2" src={Website} alt="" width={"40px"} />
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpcBankSpotlight;
